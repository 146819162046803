import { HtmlString, PerObjective } from "@evidenceb/gameplay-interfaces";
import { AIConfig } from "@evidenceb/ai-handler";
import { Resource } from "./Resources";
import { ButtonProps } from "@evidenceb/athena-common/design-system/Button";
import { FeatureFlags as ReleaseFlags } from "@evidenceb/athena-common/modules/FeatureFlags";
import { UserType } from "./User";
import {
    Recommendations,
    RecommendationsLimits,
} from "./AdaptiveTestDashboard";
import { LegacyTheme, Theme } from "./Theme";
import { MappingNode } from "./MappingNode";

export interface Config {
    versioning: number;
    declinaison: string;
    client_name: string;
    contact: string;
    developedBy: string;

    specimenVariation: string | false;

    lang?: string;
    displayedDateFormat: string;

    apiUrls: MicroServices;

    debug?: Partial<DebugOptions>;

    activate_contentPage: boolean;
    features: Partial<ConfigFlags>;
    releaseFlags?: ReleaseFlags;
    auth: AuthConfig;
    logoutUrl: {
        provider: string;
        url: string;
    }[];

    ai: AIConfig;

    /**
     * List of resources (videos, links, etc.) that are used in various contexts
     * in the app (eg learning recommendations, tutorial videos, etc.)
     */
    resources?: Resource[];
    recommendations:
        | PerObjective<Recommendations[]>
        | PerObjective<Recommendations>;
    pages: Page[];
    logos: LogosCustomization;
    placeholderImages?: PlaceholderImages;
    workModesConfig?: WorkMode[];
    theme: LegacyTheme & Theme;
    footer: Footer;
    // Temporary key, prefer entryLearningSetId in each Page
    mode?: { [key in "soloai" | "workshop" | "tuto"]: string };
    mappingTree?: MappingNode;
}

export interface DebugOptions {
    /**
     * Displays the reference of the current exercise in the player
     */
    exerciseIdentifier: boolean;
}

export interface AuthConfig {
    mode: AuthMode | AuthMode[];
    rightImage: string;
    provider?: string;
    variation?: string;
    mascotUrl?: string;
    /**
     * URL of the page that allows the user to register an account
     */
    registerURL: string;
    createAccountUrl?: string;

    /**
     * For the DirectAndAuth authentication mode, path to make a request to
     * athena-auth that returns the URL the user should be redirected to
     */
    athenaAuthPathForRedirectionQuery?: string;

    teacherAuthButton?: string;
    studentAuthButton?: string;

    errors?: { urlPath: string; title: string; description: string }[];
    qrcode?: string;
}

export interface LogosCustomization {
    avatar: string;
    avatarStudentBottomRight?: string;
    demo?: string;
    header_client: string;
    endPlaylistIcon: string;
    footer_evidenceb: string;
    footer_client: string;
    mascotIcon?: string;
    usersAvatars?: { src: string; alt: string; userType: UserType[] }[];
    pdfLogo?: string;
}

export interface PlaceholderImages {
    student?: {
        work?: string;
        dashboard?: string;
        cards?: string;
    };
    teacher?: {
        cards?: string;
        /**
         * Images to use in the subject choice when mapping is active
         */
        subjectChoice?: [string, string];
    };
}

export enum Pagetype {
    CONTENTPAGE = "CONTENTPAGE",
    DASHBOARD = "DASHBOARD",
    HOME = "HOME",
    LOGOUT = "LOGOUT",
    MODULELIST = "MODULELIST",
    SWITCH_USER_TYPE = "SWITCH_USER_TYPE",
    WORK_MODES = "WORK_MODES",
    WORKMODE_DUO = "WORKMODE_DUO",
    WORKMODE_PLAYLIST = "WORKMODE_PLAYLIST",
    WORKMODE_WORKSHOP = "WORKMODE_WORKSHOP",
    WORKMODE_TUTO = "WORKMODE_TUTO",
    WORKMODE_REVISION = "WORMODE_REVISION",
    WORK = "WORK",
    EXTERNAL = "EXTERNAL",
}

export interface UrlDisplay {
    mode: "DEFAULT" | "USER_BASED" | "EXTERNAL";
    /** Mode is used to permit different urls & labels depending on the user type
     * When mode = DEFAULT
     * - "url" and "label" are always of type "string" because there are only 1 url and 1 label for ALL users regardless of the user type
     * - {
          mode: "DEFAULT",
          label: "Info page",
          url: "info-page"
      }
     * When mode = USER_BASED
     * - "url" and "label" are always of type "string[]" because in this case there are 1 url and 1 label for EACH user type
     * - {
          mode: "USER_BASED",
          label: ["Teacher Info page", "Student Info page"],
          url: ["teacher-info-page, "student-info-page"]
      }
     * - url[0] and label[0] are always for userType.teacher
     * - url[1] and label[1] are always for userType.student
     * When mode = EXTERNAL
     * - "url" and "label" are always of type "string" because there are only 1 url and 1 label for ALL users regardless of the user type
     * It means it redirect the user to external page of the application
}*/
    url: string[] | string;
    /** Title of the page in the tab, only for ExternalPages and ContentPages */
    label?: string[] | string;
}

export type SocialNetwork = {
    name: string;
    url: string;
};

export type Footer = {
    socialNetwork: SocialNetwork[];
};

export type Page = {
    display: UrlDisplay;
    type: Pagetype;
    authorized?: (
        | UserType
        /** override default location for specific user type */
        | (Location & { for: UserType })
    )[];
    fallback?: {
        student: string;
        teacher: string;
    };
    entryLearningSetIds?: string[];
} & Location;

type Location = {
    inTopNav: boolean;
    inFooter: boolean;
    inBottomFooter?: boolean;
};

export interface MicroServices {
    match: string;
    endpoints: {
        auth: string;
        content: string;
        analytics?: string;
        users?: string;
        statements?: string;
        assetsProxy: string;
        websockets?: string;
        resources?: string;
        nlp?: string;
        vittascience?: string;
        events?: string;
        pad?: string;
    };
}

export interface SpecimenJSONConfig {
    match: string;
    variation: string;
}

export interface ApplicationsConfig {
    match: string;
    authMode: AuthMode;
    /** @default "DEFAULT"  */
    choiceType?: "LANGUAGE" | "DEFAULT";
    /** @default false */
    hideChoiceLabel?: boolean;
    availableApps: Application[];
    globalConfig?: string;
    error: any[];
}
export type Application = {
    app: string;
    selectorLabel: string;
    id?: string;
    default?: boolean;
    lang?: string;
    client_name?: string;
    globalConfig?: string;
};

/**
 * For boolean flags, default is false unless otherwise stated
 */
interface TemporaryFeatureFlags {
    /** New Module list for students (Cned project) */
    cnedModuleList: boolean;
    /**
     * Activate the teacher informational panel in the player
     *
     * [TEMP] This flag is meant to be used temporarily to be able to deplay
     * the feature in certain variations while the content is being
     * validated by the product management for other variations
     */
    teacherInfoPanel: boolean;
    /**
     * Use the module list corresponding to EVBPROD-1121
     *
     * [TEMP] This flag is meant to be used for a progressive rollout of the
     * new feature
     */
    newStudentModuleList: boolean;
    // STUDENT DASHBOARD
    /**
     * Show or not UnderstandMyAssessmentWidget
     */
    displayUnderstandMyAssessmentWidget: boolean;

    /**
     * Prevent sending events to xapi
     *
     * [TEMP] only used while we remove xapi from production
     */
    disableXapiEvents: boolean;
}
interface PermanentFeatureFlags {
    // TEACHER DASHBOARD
    teacherDashboard: boolean;
    classManagement: boolean;
    studentManagement: boolean;
    clustering: boolean;
    /**
     * Controls whether or not the class code should be shown in the
     * dashboard.
     * @default true
     */
    classCode: boolean;

    // ADAPTIVE TEST DASHBOARD
    /**
     * Show or not the bubble representing the interval around mean estimate
     */
    dislayEstimateInterval: boolean;
    /**
     * Number of recommendations to display on student dashboard
     * @default undefined
     */
    recommendationsLimits?: RecommendationsLimits;
    /**
     * For recommendations in adaptive tests:
     * Word group preceding the number that will be used as an index for ordering recommendations
     * Basis of a Regex
     * Usually the number of the word session
     * /!\ Have to be kept consistent with the descriptions in the recommendations
     */
    recommendationRegex: string;

    // FOOTER
    /**
     * Adds a button in the footer allowing to download all the user data in
     * json format
     */
    userDataDownload: boolean;
    /** @default true */
    displayDevelopedBy: boolean;
    /**
     * Choose a different variant and color for the footer buttons
     * @default undefined
     */
    footerBtnVariant: Pick<ButtonProps, "variant" | "color">;
    displayCopyright: boolean;
    /** @default true */
    footerContactBtn: boolean;

    // PLAYER
    emotionalReport: boolean;
    /**
     * Displays the Ella drawer in the student chatbot player
     */
    studentChatbotPlayerInfoPanel: boolean;
    /** Activate minitutor options */
    minitutor: Partial<{
        /**
         * Display a video toturial of an activity,
         * NB: Only available for the Wizard Shell atm
         */
        activityTutorialVideo: boolean;
    }>;
    /**
     * Display an image background in the player
     * NB: Only available for the Wizard Shell atm
     */
    playerBackground?: string;
    /**
     * Display an image background in the player
     * NB: Only available for the Wizard Shell atm
     */
    // MODULE LIST
    /**
     * In the teacher module list, when true show full title instead of "Module N"
     */
    teacherModuleListShowFullTitle: boolean;
    /**
     * Show the module map in the student module list
     */
    showModuleMapInStudentModuleList: boolean;

    // MISC
    demoMode: {
        displayIn: {
            classList: boolean;
            classDetails: boolean;
            progressionOverview: boolean;
            studentProgression: boolean;
            studentDetails: boolean;
            clustering: boolean;
        };
        message: {
            global: HtmlString;
            studentProgression: HtmlString;
            clustering: HtmlString;
        };
    };
    /**
     * Uses the Ella student homepage instead of the original homepage
     */
    useEllaStudentHomepage: boolean;
    /**
     * use custom colors in certain parts of the application
     */
    customColors: boolean;
    /**
     * Adds a button in the header that redirects to the given url
     */
    backlinkURL: string;
    /**
     * The only default statements are the student player exercise statements,
     * and the emotional report statements when the feature is activated.
     * This allows to send additional statements.
     */
    additionalStatements: Partial<{
        studentLogIn: boolean;
        /**
         * A statement sent when a student clicks on a recommendation after an
         * adaptive test
         */
        recommendationsClick: boolean;
    }>;

    // SPECIMEN
    /**
     * Informations related to the specimen
     */
    specimen: {
        /** Link to get the full version related to the specimen */
        getFullVersionLink: string;
        /** Link to the education space*/
        educationSpaceLink: string;
    };
    // MODE
    showWorkmodesPlaceholders: boolean;

    // HOME
    home?: {
        /** Paths to the images displayed in homepage user card */
        headerBackground?: {
            student?: string;
            teacher?: string;
        };
        /** Path to the mascot image displayed in WELCOME widget */
        mascot?: string;
        /** Configuration for the widgets displayed in homepage aside */
        widgets?: WidgetConfig[];
        /** Use the simplified version of student homepage */
        simpleStudentHomepage: boolean;
        /** Shows "to do" modules card on student homepage */
        showToDo?: boolean;
    };

    /**
     * Show the module map links (which open module maps in a modal)
     */
    showModuleMapLinks: boolean;

    // INFORMATION BANNERS
    informationBanners?: {
        id: string;
        content: string;
        dismissable?: boolean;
        authorized?: UserType[];
    }[];
}
export enum WidgetType {
    FREEFORM = "FREEFORM",
    GUIDE = "GUIDE",
    ABOUT = "ABOUT",
    NEWSLETTER = "NEWSLETTER",
    WELCOME = "WELCOME",
}

export type WidgetConfig = {
    userType?: UserType;
    type: WidgetType;
    content?: {
        title?: string;
        text: HtmlString;
        buttonLabel?: string;
        icon?: string;
    };
    colors?: {
        background?: string;
        text?: string;
    };
    redirect?: {
        to: string;
        altText: string;
        type: "internalRedirection" | "externalRedirection";
    };
};

export type ConfigFlags = TemporaryFeatureFlags & PermanentFeatureFlags;

export enum AuthMode {
    /**
     * No token in the URL,
     * No external authentication, both teachers & students need to authenticate themselves,
     * teachers: mail/password needed,
     * students: classroom code/student code needed
     */
    Authentication = "AUTHENTICATION",
    /**
     * Token in the URL,
     * Teachers need to type their firstname/lastname & students their classroom code/,firstname/lastname to access the app
     */
    Register = "REGISTER",
    /**
     * Token in the URL,
     * Both Teachers & students need to type their firstname/lastname to access the app
     */
    RegisterNameOnly = "REGISTER_NAMEONLY",
    /**
     * Token in the URL,
     * No extra step required, direct access to the app
     */
    DirectAccess = "DIRECT_ACCESS",
}

export interface WorkMode {
    logo?: string;
    url?: string;
    /**
     * If the status is a list, it allows to configure the workmode per user
     * type. If a user Type is omitted from the list, the workmode will not
     * appear in this user type's app
     * @default WorkmodeStatus.Active
     */
    status?: WorkModeStatus | { userType: UserType; status: WorkModeStatus }[];
    color?: string;
    type: WorkModeType;
    /**
     * @default 1
     */
    displayedSize?: workModeSize;
}

export enum workModeSize {
    Small = 1,
    Medium = 2,
    Large = 3,
}
export enum WorkModeStatus {
    Active = "ACTIVE",
    Disabled = "DISABLED",
    NotClickable = "NOT_CLICKABLE",
}

export enum WorkModeType {
    Duo = "DUO",
    SoloAI = "SOLO_AI",
    Playlist = "PLAYLIST",
    Tuto = "TUTO",
    Test = "TEST",
    Workshop = "WORKSHOP",
    StandaloneAdaptiveTest = "STANDALONE_ADAPTIVE_TEST",
    Revision = "REVISION",
}
