import { ThemeColors } from "../../interfaces/Theme";
import { addAlpha } from "../color-helpers";

const primaryColor = "#0C224D";
const primaryColorDark = "#322d35";
const teacher = "#FF8660";
const subject1 = "#71C9FA";
const subject2 = "#B1D366";
const white = "#ffffff";
const black = "#000000";
const lightGrey = "rgba(4, 20, 47, 0.2)";
const navigation = "#264989";

export const darkTheme: ThemeColors = {
    primaryColor: primaryColor,
    primaryColorDark: primaryColorDark,

    defaultBlack: black,
    defaultWhite: white,

    blockHeader: primaryColor,
    blockFooter: primaryColor,
    blockPage: "#122D61",
    contentPage: white,
    block: "#113C72",

    blockBorder: "#113C72",

    blockItem1Default: "#134684",
    blockItem1Selected: "#12488a",

    blockItem1SelectedTeacher: teacher,

    blockItem1SelectedSubject1: subject1,
    blockItem1SelectedSubject2: subject2,

    blockItem1BorderDefault: addAlpha("#135CB3", 8),
    blockItem1BorderSelected: "#12488a",

    blockItem2Default: "#11316f",

    textMainDefault: white,
    textMainOff: addAlpha(white, 4),
    textMainDisabled: addAlpha(white, 2),
    textMainInverted: "#082367",

    textH1ContentPageDefault: black,

    textHeaderDefault: white,
    textFooterDefault: white,

    textSubject1Default: white,
    textSubject1Off: addAlpha(white, 2),
    textSubject1Disabled: addAlpha(white, 2),

    textSubject2Default: white,
    textSubject2Off: addAlpha(white, 2),
    textSubject2Disabled: addAlpha(white, 2),

    elementGrey10: addAlpha(white, 1),
    elementGrey20: addAlpha(white, 2),
    elementGrey50: addAlpha(white, 5),
    elementGrey80: addAlpha(white, 8),

    elementEvidencebDefault: "#AC9BFF",
    elementEvidencebDefaultLight: "#D9D1FF",
    elementEvidencebHover: white,
    elementEvidencebDisabled: addAlpha("#AC9BFF", 2.5),
    elementEvidencebIdle: lightGrey,
    elementEvidencebUnfocused: addAlpha(white, 4),

    elementTeacherDefault: teacher,
    elementTeacherHover: white,
    elementTeacherDisabled: addAlpha(teacher, 4),
    elementTeacherIdle: lightGrey,
    elementTeacherUnfocused: addAlpha(white, 4),

    elementSubject1Default: subject1,
    elementSubject1Hover: white,
    elementSubject1Disabled: addAlpha(subject1, 4),
    elementSubject1Idle: lightGrey,
    elementSubject1Unfocused: addAlpha(white, 4),

    elementSubject2Default: subject2,
    elementSubject2Hover: white,
    elementSubject2Disabled: addAlpha(subject2, 4),
    elementSubject2Idle: lightGrey,
    elementSubject2Unfocused: addAlpha(white, 4),

    elementNavigationDefault: navigation,
    elementNavigationHover: white,
    elementNavigationDisabled: addAlpha(primaryColor, 4),

    elementButtonPrimaryDefault: navigation,
    elementButtonPrimaryBorderDefault: navigation,
    elementButtonPrimaryTextDefault: white,

    elementButtonPrimaryHover: white,
    elementButtonPrimaryBorderHover: white,
    elementButtonPrimaryTextHover: navigation,

    elementButtonSecondaryDefault: "transparent",
    elementButtonSecondaryBorderDefault: white,
    elementButtonSecondaryTextDefault: white,

    elementButtonSecondaryHover: white,
    elementButtonSecondaryBorderHover: white,
    elementButtonSecondaryTextHover: navigation,

    elementButtonCustomHover: white,

    elementTabPrimaryActiveTextDefault: "#082367",
    elementTabPrimaryActiveTextHover: "#082367",

    elementTabPrimaryActiveBorderDefault: "#FF8660",

    elementTabPrimaryInactiveDefault: "#113C72",
    elementTabPrimaryInactiveHover: "#264989",

    elementTabPrimaryInactiveTextDefault: addAlpha(white, 8),
    elementTabPrimaryInactiveTextHover: addAlpha(white, 8),

    elementTabPrimaryInactiveBorderDefault: addAlpha(white, 5),
    elementTabPrimaryInactiveBorderHover: addAlpha(white, 5),

    elementBackgroundAvatar: white,

    elementObjectiveBackgroundCompleted: teacher,
    elementObjectiveBackgroundInProgress: addAlpha(teacher, 6),
    elementObjectiveBackgroundNotExplored: primaryColor,

    alertCorrect: "#88E688",
    alertNeutral: white,
    alertFailure: "#FF8787",

    alertUp: "78, 173, 242", //"#4EADF2"
    alertDown: "255, 96, 186", //"#FF60BA"

    ressourcesFillSolo: "#0765E0",
    ressourcesFillDuo: "#5855E5",
    ressourcesFillPlaylist: "#E09452",
    ressourcesFillTuto: "#34A762",
    ressourcesFillWorkshop: "#C85757",

    thumbnailMediaIllustrationFillNeutral: addAlpha(black, 3),
    thumbnailMediaIllustrationFillHover:
        "linear-gradient(87.69deg, rgba(19, 92, 179, 0.26) -2.41%, rgba(67, 182, 218, 0.26) 57.99%, rgba(153, 191, 248, 0.26) 101.14%)",

    thumbnailMediaStrokeNeutral: addAlpha(white, 1),
    thumbnailMediaStrokeHover: addAlpha(white, 1),

    elementSubject1DefaultDarkBg: "#71C9FA",
    elementSubject1DisabledDarkBg: "rgba(78, 183, 242, 0.3)",
    elementSubject2DefaultDarkBg: "#B1D366",
    elementSubject2DisabledDarkBg: "rgba(177, 211, 102, 0.4)",
};
